@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #0d1117, #161b22);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  box-sizing: border-box; /* Ensure padding is included in element's total width */
  padding: 50px;
}

.App-logo {
  height: 150px;
  pointer-events: none;
  margin-bottom: 20px;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  font-size: 1.8rem;
  font-weight: 500;
  margin: 20px;
}

h2 {
  font-size: 1rem;
  margin-bottom: 30px;
  font-weight: 300;
}

button {
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  margin-top: 20px;
  cursor: pointer;
  background-color: #58a6ff;
  border: none;
  border-radius: 25px;
  color: #ffffff;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

button:hover {
  background-color: #1f6feb;
  transform: translateY(-3px);
}

button:active {
  background-color: #1e60db;
  transform: translateY(1px);
}

.claim-button {
  background-color: #1e60db;
}

.claim-button:hover {
  background-color: #1f6feb;
}

.claim-button:active {
  background-color: #1f6feb;
}

.error-message {
  margin-top: 10px;
  color: #ff4757;
  font-size: 0.9rem;
}

.success-message {
  margin-top: 10px;
  color: #28a745;
  font-size: 0.9rem;
}

/* Media queries for responsiveness */
@media (max-width: 600px) {
  .App {
    padding: 30px;
    width: 100%;
  }

  .App-logo {
    height: 120px;
  }

  h1 {
    font-size: 2rem;
  }

  button {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }

  .claim-button {
    padding: 0.5rem 1rem;
  }

  .error-message, .success-message {
    font-size: 0.8rem;
  }
}
